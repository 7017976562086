@import 'variables';

.care {
    &__hero {
        position: relative;
        height: 46.25rem;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            height: auto;
        }

        >img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            @media (max-width: @mobileBreakpoint) {
                position: relative;
                width: 100%;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            .content();

            @media (max-width: @mobileBreakpoint) {
                display: none;
            }

            .sections {
                position: absolute;
                left: @spacing2XL;
                top: @spacingM;

                li, a {
                    color: @colorBaseColor;
                }
            }

            &__data {
                position: relative;
                padding-right: 8rem;
                width: 37.25rem;

                &__title {
                    .fontBarlow();

                    font-size: 6rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1;
                    color: @colorBaseColor;
                    text-transform: uppercase;
                }

                &__text {
                    margin-top: 2.5rem;
                    padding-right: 8rem;
                    font-size: 1.375rem;
                    line-height: 1.4;
                    color: @colorBaseColor;
                }

                &__button {
                    margin-top: 3.5rem;

                    button {
                        .buttonPrimary();
                    }
                }
            }
        }
    }

    &__third {
        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: @spacing5XL;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                padding-bottom: @spacingL;
                flex-direction: column;
            }

            &__left {
                width: 37.1875rem;
                margin-right: 17.875rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-right: 0;
                }

                &__section {
                    font-size: 0.875rem;
                    line-height: 0.86;
                    letter-spacing: 7px;
                    color: #8b8b8b;
                    text-transform: uppercase;
                }

                &__title {
                    .fontBarlow();

                    margin-top: @spacingM;
                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2rem;
                    }
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;
                    width: 37.1875rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                        margin-top: @spacingM;
                        font-size: 1rem;
                    }
                }

                &__lines {
                    margin-top: 4.5rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                    }

                    &__line {
                        margin-bottom: @spacingM;
                        display: flex;
                        flex-direction: row;

                        svg {
                            width: 1.5rem;
                            height: 1.5rem;
                            margin-right: @spacingXXS;
                            color: @colorDark;
                        }

                        &__text {
                            font-size: 1.125rem;
                            line-height: 1.6;
                            color: @colorDark;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1rem;
                            }
                        }
                    }
                }

                &__info {
                    margin-top: 4.5rem;
                    padding: 2.5rem;
                    background-color: #f5f5f5;
                    border-radius: 1rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                        padding: @spacingM;
                    }

                    &__name {
                        .fontBarlow();

                        font-size: 2rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        color: @colorDark;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.5rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        color: @colorDark;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1rem;
                            margin-top: @spacingS;
                        }
                    }
                }
            }

            &__photo {
                width: 29.375rem;
                height: 45.625rem;
                border-radius: 1rem;
                background-color: #c8c8c8;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    height: auto;
                    margin-top: @spacingM;
                }

                img {
                    height: 100%;

                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}
