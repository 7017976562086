@import 'variables';

.menu {
    position: sticky;
    top: 40px;
    background-color: @colorBaseColor;
    z-index: 200;
    border-bottom: 1px solid @colorNeutral1;

    &__content {
        .content();

        padding-top: @spacingXS;
        padding-bottom: @spacingL;

        @media (max-width: @mobileBreakpoint) {
            padding-top: @spacingXS;
            padding-bottom: @spacingXS;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 0.75rem;

            &__left {
                @media (max-width: @mobileBreakpoint) {
                    opacity: 0;
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                align-items: center;

                &__buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    button {
                        display: inline-flex;
                        padding-right: @spacingXXS;
                        margin-right: @spacingXXS;
                        border-right: 1px solid @colorDark;
                        color: @colorDark;
                        font-size: 0.75rem;
                        white-space: nowrap;
                    }
                    button:last-of-type {
                        border-right: 0;
                        margin-right: 0;
                        padding-right: 0;
                    }
                    button.active {
                        font-weight: bold;
                    }

                    button:nth-child(2) {
                        @media (max-width: @mobileBreakpoint) {
                            border-right: 0;
                            margin-right: 0;
                            padding-right: 0;
                        }
                    }

                    button:nth-child(3), button:nth-child(4) {
                        @media (max-width: @mobileBreakpoint) {
                            display: none;
                        }
                    }
                }

                &__settings {

                }
            }
        }

        &__data {
            margin-top: @spacingM;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingS;
            }

            button.menu__content__data__logo {
                cursor: pointer;
                color: @colorBlue;
                border-left: 8px solid @colorPrimary;
                padding-left: 1rem;
                font-size: 2rem;
                font-weight: bold;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                    padding-left: 0.5rem;
                    position: absolute;
                    left: @spacingM;
                    top: @spacingXS;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    justify-content: center;
                    flex: 1;
                    flex-wrap: wrap;
                }

                button {
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 1.83;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    color: @colorDark;
                    margin-right: @spacingM;
                    border-bottom: 2px solid transparent;

                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                        margin-right: @spacingS;
                    }
                }
                button:last-of-type {
                    margin-right: 0;
                }
                button.active {
                    border-bottom: 2px solid @colorPrimary;

                    @media (max-width: @mobileBreakpoint) {
                        color: @colorSecondary !important;
                    }
                }

                button:nth-child(1), button:nth-child(2), button:nth-child(3) {
                    color: @colorSecondary;

                    @media (max-width: @mobileBreakpoint) {
                        color: @colorDark;
                    }
                }

                button:nth-child(1), button:nth-child(2), button:nth-child(3), button:nth-child(4), button:nth-child(5) {
                     @media (max-width: @mobileBreakpoint) {
                         display: inline-flex;
                     }
                 }

                button:nth-child(5) {
                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                    }
                }
            }

            &__right {
                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }
            }
        }
    }
}
