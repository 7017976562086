@import 'variables';

.contact {

    &__content {
        .content();

        &__title {
            .fontBarlow();

            margin-top: 3.5rem;
            font-size: 6rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
                font-size: 4rem;
            }
        }

        &__info {
            display: flex;
            flex-direction: row;
            margin-top: 2.5rem;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
                flex-direction: column;
            }

            &__left {
                width: 29rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                }

                &__text {
                    font-size: 1.375rem;
                    line-height: 1.4;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1rem;
                    }
                }

                button {
                    margin-top: 3.5rem;
                    font-size: 1.375rem;
                    font-weight: bold;
                    line-height: 1.4;
                    color: @colorSecondary;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                        font-size: 1.25rem;
                    }

                    svg {
                        margin-right: @spacingXXS;
                    }
                }
                button:hover {
                    text-decoration: underline;
                }
            }

            &__right {
                display: flex;
                flex-direction: row;
                margin-left: 25.875rem;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                    margin-left: 0;
                }

                &__panel {
                    margin-right: @spacingL;
                    width: 13.6875rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        width: 100%;
                        margin-top: @spacingL;
                    }

                    &__title {
                        font-size: 1.125rem;
                        font-weight: bold;
                        line-height: 1.6;
                        color: @colorDark;
                    }

                    &__text {
                        margin-top: @spacingS;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        color: @colorDark;
                    }
                }
            }
        }
    }

    &__map {
        margin-top: 6rem;

        iframe {
            display: block;
            width: 100%;
        }
    }
}
