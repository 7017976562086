@import 'variables';

.footer {
    background-color: @colorDark;

    &__content {
        .content();

        padding-top: 4.5rem;
        padding-bottom: @spacingXL;
        color: @colorBaseColor;

        @media (max-width: @mobileBreakpoint) {
            padding-top: @spacingM;
            padding-bottom: @spacingM;
        }

        &__panels {
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                flex-direction: column;
            }

            &__panel {
                position: relative;
                margin-right: @spacingL;
                flex: 1;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                    margin-bottom: @spacingM;
                }

                &__social {
                    position: absolute;
                    right: 0;
                    top: 0;

                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }

                    img {
                        display: block;
                        margin-bottom: @spacingM;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }
                }

                &__logo {

                    >svg {
                        width: auto;
                        height: 24px;
                    }
                }

                &__slogan {
                    font-size: 1.125rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: left;
                    color: #fdfdfd;
                    width: 8.125rem;
                    opacity: 0.75;

                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }

                &__title {
                    .fontBarlow();

                    font-size: 1.25rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: normal;
                    line-height: 1.1;
                    letter-spacing: 1px;
                    text-align: left;
                    padding-bottom: @spacingS;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1rem;
                    }
                }

                button {
                    font-size: 1.125rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: left;
                    margin-top: @spacingS;
                    display: block;
                    color: @colorBaseColor;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingXS;
                        font-size: 0.875rem;
                    }
                }

                &__contact {
                    margin-top: @spacingS;
                    margin-bottom: @spacingL;
                    display: flex;
                    flex-direction: row;

                    &__left {
                        margin-right: @spacingXXS;

                        svg {
                            width: 24px;
                            height: 24px;
                            color: #fdfdfd;
                        }
                    }

                    &__right {
                        &__value {
                            font-size: 1.375rem;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: #fdfdfd;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1.25rem;
                            }
                        }

                        &__text {
                            margin-top: @spacingXXS;
                            font-size: 0.75rem;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.3;
                            letter-spacing: normal;
                            text-align: left;
                            color: #fdfdfd;
                            opacity: .75;
                        }
                    }
                }
            }
            .footer__content__panels__panel:last-of-type {
                margin-right: 0;
            }
        }

        &__down {
            margin-top: 4.5rem;
            padding-top: 1.5rem;
            border-top: 1px solid #e6e6e6;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
            }

            &__text {
                font-size: 0.75rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: right;
                color: #fdfdfd;
                opacity: .75;
            }
        }
    }
}
