@font: 1rem;
@pcBreakpoint: 1599px;
@mobileBreakpoint: 1200px;

@spacingXXS: 0.5rem;
@spacingXS: 0.75rem;
@spacingS: 1rem;
@spacingM: 1.5rem;
@spacingL: 2rem;
@spacingXL: 3rem;
@spacing2XL: 4rem;
@spacing3XL: 5.5rem;
@spacing4XL: 7rem;
@spacing5XL: 9rem;
@spacing6XL: 11rem;
@spacing7XL: 13.5rem;
@rem10: 160px;

@colorNeutral1: #f5f5f5;
@colorNeutral2: #8b8b8b;
@colorNeutral3: #e1e1e1;
@colorDark: #1f1f1f;
@colorBaseColor: #fdfdfd;
@colorOrange: #f39a33;
@colorGreen: #409b04;
@colorRed: #c24646;

@colorPrimary: rgb(255, 235, 0);
@colorSecondary: rgb(43, 157, 180);
@colorBlue: rgb(28, 23, 51);
@colorBlack: black;

.content() {
    margin: auto;
    width: 100%;
    max-width: 1600px;
    box-sizing: border-box;
    padding: 0 @spacing2XL;

    @media (max-width: @mobileBreakpoint) {
        padding-left: @spacingM;
        padding-right: @spacingM;
    }
}

.font() {
    font-family: 'Barlow', sans-serif !important;
    font-size: @font;
    font-style: normal;
    font-weight: normal;
}

.fontBarlow() {
    font-family: 'Barlow Condensed', sans-serif !important;
    font-size: @font;
    font-style: normal;
    font-weight: normal;
}

.button() {
    height: 3.5rem;
    border-radius: 0.5rem;
    background-color: transparent !important;
    border: 1px solid @colorDark !important;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: @colorDark;
    text-transform: uppercase;
    line-height: 1.4;
    padding: 0 2.25rem;

    @media (max-width: @mobileBreakpoint) {
        padding: 0 1.5rem;
        white-space: nowrap;
    }
}

.buttonPrimary() {
    height: 3.5rem;
    border-radius: 0.5rem;
    background-color: @colorPrimary !important;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: @colorBlue;
    text-transform: uppercase;
    border: 0 !important;
    line-height: 1.4;
    padding: 0 2.25rem;

    @media (max-width: @mobileBreakpoint) {
        padding: 0 1.5rem;
        white-space: nowrap;
    }
}

.buttonWhite() {
    height: 3.5rem;
    border-radius: 0.5rem;
    background-color: transparent !important;
    border: 1px solid @colorBaseColor !important;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: @colorBaseColor;
    text-transform: uppercase;
    line-height: 1.4;
    padding: 0 2.25rem;

    @media (max-width: @mobileBreakpoint) {
        padding: 0 1.5rem;
        white-space: nowrap;
    }
}

.rotate(@deg) {
    -webkit-transform: rotate(@deg);
    transform: rotate(@deg);
}
