@import 'variables';

.article {

    &__content {
        .content();

        &__data {
            padding-top: 2.5rem;
            margin: auto;
            width: 60.625rem;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
            }

            &__title {
                .fontBarlow();

                font-size: 6rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                width: 45rem;
                margin: auto;
                line-height: 1;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    font-size: 2rem;
                }
            }

            &__info {
                padding-top: @spacingS;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 45rem;
                margin: auto;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                }

                &__value {
                    padding-left: @spacingS;
                    margin-right: @spacingL;
                    border-left: 1px solid #c8c8c8;
                    line-height: 1.4;
                    color: #8b8b8b;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: @spacingXS;
                        padding-left: @spacingXS;
                        font-size: 0.75rem;
                    }
                }
                &__value:nth-child(2) {
                    @media (max-width: @mobileBreakpoint) {
                        display: none;
                    }
                }
            }

            &__text {
                padding-top: 4.5rem;
                width: 45rem;
                margin: auto;
                font-size: 1.375rem;
                line-height: 1.4;

                @media (max-width: @mobileBreakpoint) {
                    padding-top: @spacingL;
                    width: 100%;
                    font-size: 1rem;
                }
            }

            &__photo {
                margin-top: 6rem;
                height: 34.125rem;
                border-radius: 1rem;
                overflow: hidden;
                background-color: rgb(250,250,250);
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingL;
                    height: auto;
                }

                img {
                    height: 100%;

                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &__html {
                padding-top: 6rem;
                padding-left: 7.8125rem;
                padding-bottom: @spacing5XL;
                width: 37.1875rem;
                font-size: 1.125rem;
                line-height: 1.6;

                @media (max-width: @mobileBreakpoint) {
                    padding-top: @spacingL;
                    padding-left: 0;
                    padding-bottom: @spacingL;
                    width: 100%;
                    font-size: 1rem;
                }
            }
        }
    }
}
