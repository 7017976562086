.contact__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .contact__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.contact__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 3.5rem;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .contact__content__title {
    margin-top: 1.5rem;
    font-size: 4rem;
  }
}
.contact__content__info {
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
}
@media (max-width: 1200px) {
  .contact__content__info {
    margin-top: 1.5rem;
    flex-direction: column;
  }
}
.contact__content__info__left {
  width: 29rem;
}
@media (max-width: 1200px) {
  .contact__content__info__left {
    width: 100%;
  }
}
.contact__content__info__left__text {
  font-size: 1.375rem;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .contact__content__info__left__text {
    font-size: 1rem;
  }
}
.contact__content__info__left button {
  margin-top: 3.5rem;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.4;
  color: #2b9db4;
}
@media (max-width: 1200px) {
  .contact__content__info__left button {
    margin-top: 1.5rem;
    font-size: 1.25rem;
  }
}
.contact__content__info__left button svg {
  margin-right: 0.5rem;
}
.contact__content__info__left button:hover {
  text-decoration: underline;
}
.contact__content__info__right {
  display: flex;
  flex-direction: row;
  margin-left: 25.875rem;
}
@media (max-width: 1200px) {
  .contact__content__info__right {
    flex-direction: column;
    margin-left: 0;
  }
}
.contact__content__info__right__panel {
  margin-right: 2rem;
  width: 13.6875rem;
}
@media (max-width: 1200px) {
  .contact__content__info__right__panel {
    margin-right: 0;
    width: 100%;
    margin-top: 2rem;
  }
}
.contact__content__info__right__panel__title {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.6;
  color: #1f1f1f;
}
.contact__content__info__right__panel__text {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
.contact__map {
  margin-top: 6rem;
}
.contact__map iframe {
  display: block;
  width: 100%;
}
