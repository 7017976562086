.article__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .article__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.article__content__data {
  padding-top: 2.5rem;
  margin: auto;
  width: 60.625rem;
}
@media (max-width: 1200px) {
  .article__content__data {
    width: 100%;
  }
}
.article__content__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  width: 45rem;
  margin: auto;
  line-height: 1;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .article__content__data__title {
    width: 100%;
    font-size: 2rem;
  }
}
.article__content__data__info {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45rem;
  margin: auto;
}
@media (max-width: 1200px) {
  .article__content__data__info {
    width: 100%;
  }
}
.article__content__data__info__value {
  padding-left: 1rem;
  margin-right: 2rem;
  border-left: 1px solid #c8c8c8;
  line-height: 1.4;
  color: #8b8b8b;
}
@media (max-width: 1200px) {
  .article__content__data__info__value {
    margin-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 0.75rem;
  }
}
@media (max-width: 1200px) {
  .article__content__data__info__value:nth-child(2) {
    display: none;
  }
}
.article__content__data__text {
  padding-top: 4.5rem;
  width: 45rem;
  margin: auto;
  font-size: 1.375rem;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .article__content__data__text {
    padding-top: 2rem;
    width: 100%;
    font-size: 1rem;
  }
}
.article__content__data__photo {
  margin-top: 6rem;
  height: 34.125rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .article__content__data__photo {
    margin-top: 2rem;
    height: auto;
  }
}
.article__content__data__photo img {
  height: 100%;
}
@media (max-width: 1200px) {
  .article__content__data__photo img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.article__content__data__html {
  padding-top: 6rem;
  padding-left: 7.8125rem;
  padding-bottom: 9rem;
  width: 37.1875rem;
  font-size: 1.125rem;
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .article__content__data__html {
    padding-top: 2rem;
    padding-left: 0;
    padding-bottom: 2rem;
    width: 100%;
    font-size: 1rem;
  }
}
