.luna__hero {
  position: relative;
  height: 46.25rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .luna__hero {
    height: auto;
  }
}
.luna__hero > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1200px) {
  .luna__hero > img {
    position: relative;
    height: auto;
  }
}
.luna__hero__content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .luna__hero__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__hero__content {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
  }
}
.luna__hero__content .sections {
  position: absolute;
  left: 4rem;
  top: 1.5rem;
}
@media (max-width: 1200px) {
  .luna__hero__content .sections {
    display: none;
  }
}
.luna__hero__content .sections li,
.luna__hero__content .sections a {
  color: #fdfdfd;
}
.luna__hero__content__data {
  position: relative;
  width: 42rem;
  background-color: rgba(43, 157, 180, 0.85);
  border-radius: 1rem;
  padding: 2rem;
}
@media (max-width: 1200px) {
  .luna__hero__content__data {
    width: 100%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
}
.luna__hero__content__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1;
  color: #fdfdfd;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__hero__content__data__title {
    font-size: 3rem;
  }
}
.luna__hero__content__data__text {
  margin-top: 2.5rem;
  padding-right: 8rem;
  font-size: 1.375rem;
  line-height: 1.4;
  color: #fdfdfd;
}
@media (max-width: 1200px) {
  .luna__hero__content__data__text {
    margin-top: 1rem;
    padding-right: 0;
    font-size: 1rem;
  }
}
.luna__hero__content__data__button {
  margin-top: 3.5rem;
}
@media (max-width: 1200px) {
  .luna__hero__content__data__button {
    margin-top: 1rem;
  }
}
.luna__hero__content__data__button button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #ffeb00 !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1c1733;
  text-transform: uppercase;
  border: 0 !important;
  line-height: 1.4;
  padding: 0 2.25rem;
  font-size: 1.25rem;
  text-transform: none !important;
}
@media (max-width: 1200px) {
  .luna__hero__content__data__button button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
@media (max-width: 1200px) {
  .luna__hero__content__data__button button {
    height: auto;
    white-space: normal !important;
    font-size: 0.875rem;
    padding: 1rem 2rem;
    text-align: left;
  }
}
.luna__hero__content__data__text-mini {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-stretch: condensed;
  font-style: italic;
  margin-top: 1rem;
  font-size: 1rem;
  color: #fdfdfd;
}
.luna__values {
  background-color: #f5f5f5;
}
.luna__values__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 8.5rem;
}
@media (max-width: 1200px) {
  .luna__values__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__values__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.luna__values__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  text-align: center;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__values__content__title {
    font-size: 2rem;
  }
}
.luna__values__content__panels {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .luna__values__content__panels {
    margin-top: 0;
    flex-direction: column;
  }
}
.luna__values__content__panels__panel {
  flex: 1;
  padding: 0 1.5rem;
  margin-right: 2rem;
  border-left: 1px solid #c8c8c8;
}
@media (max-width: 1200px) {
  .luna__values__content__panels__panel {
    margin-right: 0;
    margin-top: 2rem;
  }
}
.luna__values__content__panels__panel > svg {
  width: 56px;
  height: 56px;
  color: #c8c8c8;
}
.luna__values__content__panels__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 4.5rem;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__values__content__panels__panel__title {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
.luna__values__content__panels__panel__text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__values__content__panels__panel__text {
    font-size: 1rem;
  }
}
.luna__values__content__panels__panel button {
  margin-top: 3.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.7px;
  color: #2b9db4;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__values__content__panels__panel button {
    margin-top: 1.5rem;
    white-space: nowrap;
  }
}
.luna__values__content__panels__panel button svg {
  margin-left: 0.5rem;
}
.luna__values__content__panels__panel button:hover {
  text-decoration: underline;
}
.luna__values__content__panels__panel:last-of-type {
  margin-right: 0;
}
.luna__first__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
}
@media (max-width: 1200px) {
  .luna__first__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__first__content {
    padding-top: 2rem;
  }
}
.luna__first__content__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.luna__first__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__first__content__title {
    font-size: 2rem;
  }
}
.luna__first__content__photos {
  margin-top: 2.5rem;
  font-size: 1.25rem;
  color: #1f1f1f;
  width: 37.1875rem;
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .luna__first__content__photos {
    margin-top: 1.5rem;
    font-size: 1rem;
    width: 100%;
  }
}
.luna__first__content__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
  width: 37.1875rem;
}
@media (max-width: 1200px) {
  .luna__first__content__text {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.luna__first__content__button {
  margin-top: 3.5rem;
}
@media (max-width: 1200px) {
  .luna__first__content__button {
    margin-top: 1.5rem;
  }
}
.luna__first__content__button button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #ffeb00 !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1c1733;
  text-transform: uppercase;
  border: 0 !important;
  line-height: 1.4;
  padding: 0 2.25rem;
  font-size: 1.25rem;
}
@media (max-width: 1200px) {
  .luna__first__content__button button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
@media (max-width: 1200px) {
  .luna__first__content__button button {
    font-size: 1rem;
  }
}
.luna__first__content__text-mini {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  margin-top: 1rem;
  font-size: 1rem;
  color: #1f1f1f;
  font-stretch: condensed;
  font-style: italic;
}
.luna__first__content__panels {
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .luna__first__content__panels {
    margin-top: 1.5rem;
    flex-direction: column-reverse;
  }
}
.luna__first__content__panels__left {
  width: 45rem;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left {
    width: 100%;
  }
}
.luna__first__content__panels__left__photo {
  height: 31.625rem;
  background-color: #d9d9d9;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__photo {
    height: auto;
  }
}
.luna__first__content__panels__left__photo img {
  height: 100%;
  width: auto;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__photo img {
    display: block;
    height: auto;
    width: 100%;
  }
}
.luna__first__content__panels__left__texts {
  margin-top: 4.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__texts {
    margin-top: 1.5rem;
    flex-direction: column;
  }
}
.luna__first__content__panels__left__texts__text {
  flex: 1;
}
.luna__first__content__panels__left__texts__text__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__texts__text__title {
    font-size: 1.5rem;
  }
}
.luna__first__content__panels__left__texts__text__value {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__texts__text__value {
    font-size: 1rem;
  }
}
.luna__first__content__panels__left__texts__text:first-of-type {
  margin-right: 3rem;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__left__texts__text:first-of-type {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.luna__first__content__panels__right {
  margin-left: 12.8125rem;
  width: 26.4375rem;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__right {
    margin-left: 0;
    width: 100%;
    margin-top: 1.5rem;
  }
}
.luna__first__content__panels__right__row {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}
.luna__first__content__panels__right__row__number {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.6;
  color: #c8c8c8;
}
.luna__first__content__panels__right__row__text {
  margin-left: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__first__content__panels__right__row__text {
    font-size: 1rem;
  }
}
.luna__first__plan {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media (max-width: 1200px) {
  .luna__first__plan {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.luna__first__plan img {
  display: block;
  width: 100%;
}
.luna__first__photos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .luna__first__photos {
    margin-bottom: 3rem;
  }
}
.luna__first__photos__title {
  width: 100%;
  margin-bottom: 3rem;
}
.luna__first__photos__title__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  font-stretch: condensed;
  font-style: italic;
  font-weight: bold;
  line-height: 1.1;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__first__photos__title__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__first__photos__title__content {
    font-size: 1.5rem;
  }
}
.luna__first__photos button {
  width: calc(100% / 5);
}
@media (max-width: 1200px) {
  .luna__first__photos button {
    width: calc(100% / 2);
  }
}
.luna__first__photos button img {
  display: block;
  width: 100%;
}
.luna__second {
  background-color: #f5f5f5;
}
.luna__second__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 9rem;
}
@media (max-width: 1200px) {
  .luna__second__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__second__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.luna__second__content__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.luna__second__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__second__content__title {
    font-size: 2rem;
  }
}
.luna__second__content__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
  width: 37.1875rem;
}
@media (max-width: 1200px) {
  .luna__second__content__text {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.luna__second__content__panels__panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6rem;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel {
    margin-top: 2rem;
    flex-direction: column;
  }
}
.luna__second__content__panels__panel__left {
  margin-left: 7.8125rem;
  width: 37.1875rem;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__left {
    margin-left: 0;
    width: 100%;
  }
}
.luna__second__content__panels__panel__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__left__title {
    font-size: 1.5rem;
  }
}
.luna__second__content__panels__panel__left__text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__left__text {
    font-size: 1rem;
  }
}
.luna__second__content__panels__panel__left button {
  margin-top: 4.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.7px;
  color: #2b9db4;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__left button {
    margin-top: 1.5rem;
  }
}
.luna__second__content__panels__panel__left button svg {
  margin-left: 0.5rem;
}
.luna__second__content__panels__panel__left button:hover {
  text-decoration: underline;
}
.luna__second__content__panels__panel__photo {
  margin-left: 9.8125rem;
  width: 29.4375rem;
  height: 29.4375rem;
  background-color: #d9d9d9;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__photo {
    margin-left: 0;
    width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }
}
.luna__second__content__panels__panel__photo img {
  height: 100%;
}
@media (max-width: 1200px) {
  .luna__second__content__panels__panel__photo img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.luna__third__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .luna__third__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__third__content {
    padding-top: 2rem;
    flex-direction: column;
  }
}
.luna__third__content__left {
  width: 29.3125rem;
}
@media (max-width: 1200px) {
  .luna__third__content__left {
    width: 100%;
  }
}
.luna__third__content__left__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.luna__third__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__third__content__left__title {
    font-size: 2rem;
  }
}
.luna__third__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__third__content__left__text {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.luna__third__content__right {
  position: relative;
  flex: 1;
  margin-left: 9.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .luna__third__content__right {
    margin-left: 0;
    flex-direction: column;
    padding-top: 2rem;
  }
}
.luna__third__content__right__item {
  margin-right: 1.5rem;
  padding-left: 1.5rem;
  margin-bottom: 9rem;
  width: calc((50% - 1.5rem * 2));
  border-left: 1px solid #c8c8c8;
}
@media (max-width: 1200px) {
  .luna__third__content__right__item {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%;
  }
}
.luna__third__content__right__item__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__third__content__right__item__name {
    font-size: 1.5rem;
  }
}
.luna__third__content__right__item__text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__third__content__right__item__text {
    font-size: 1rem;
    padding-right: 0.75rem;
  }
}
.luna__third__content__right__item button {
  margin-top: 4.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.7px;
  color: #2b9db4;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__third__content__right__item button {
    margin-top: 1.5rem;
  }
}
.luna__third__content__right__item button svg {
  margin-left: 0.5rem;
}
.luna__third__content__right__item button:hover {
  text-decoration: underline;
}
.luna__third__content__right .luna__third__content__right__item:nth-child(2n + 2) {
  margin-right: 0;
}
.luna__down {
  background-color: #f5f5f5;
}
.luna__down__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 9rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .luna__down__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .luna__down__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column-reverse;
  }
}
.luna__down__content__left {
  margin-right: 9.875rem;
  width: 29.3125rem;
}
@media (max-width: 1200px) {
  .luna__down__content__left {
    margin-right: 0;
    width: 100%;
  }
}
.luna__down__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .luna__down__content__left__title {
    font-size: 2rem;
  }
}
.luna__down__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .luna__down__content__left__text {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.luna__down__content__left button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: transparent !important;
  border: 1px solid #1f1f1f !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1f1f1f;
  text-transform: uppercase;
  line-height: 1.4;
  padding: 0 2.25rem;
  margin-top: 4.5rem;
}
@media (max-width: 1200px) {
  .luna__down__content__left button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
@media (max-width: 1200px) {
  .luna__down__content__left button {
    margin-top: 2rem;
  }
}
.luna__down__content__photo {
  width: 45rem;
  height: 30rem;
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .luna__down__content__photo {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
}
.luna__down__content__photo img {
  width: 100%;
}
@media (max-width: 1200px) {
  .luna__down__content__photo img {
    display: block;
  }
}
