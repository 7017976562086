@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Ccs8.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5Vop.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPCw.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4A.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT3w.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xY.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3E.ttf) format('truetype');
}
body {
  padding: 0;
  margin: 0;
  background-color: #fdfdfd;
  color: #1f1f1f;
  font-family: 'Barlow', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}
h1,
h2,
h3 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}
html {
  font-size: 16px;
}
@media (max-width: 1550px) {
  html {
    font-size: 15.5px;
  }
}
@media (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}
@media (max-width: 1450px) {
  html {
    font-size: 14.5px;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 1350px) {
  html {
    font-size: 13.5px;
  }
}
@media (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 1250px) {
  html {
    font-size: 12.5px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}
button {
  border: 0;
  background-color: transparent;
  font-family: 'Barlow', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
}
a {
  text-decoration: none;
}
#screen-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.snackbar .snackbar-content {
  box-shadow: none !important;
}
.snackbar .snackbar-message {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  padding: 0 2rem;
}
.snackbar .snackbar-message svg {
  margin-right: 10px;
}
.snackbar.success .snackbar-content {
  background-color: #409b04;
}
.snackbar.error .snackbar-content {
  background-color: #c24646;
}
.snackbar.warning .snackbar-content {
  background-color: #f39a33;
}
