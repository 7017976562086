.care__hero {
  position: relative;
  height: 46.25rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .care__hero {
    height: auto;
  }
}
.care__hero > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1200px) {
  .care__hero > img {
    position: relative;
    width: 100%;
  }
}
.care__hero__content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .care__hero__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .care__hero__content {
    display: none;
  }
}
.care__hero__content .sections {
  position: absolute;
  left: 4rem;
  top: 1.5rem;
}
.care__hero__content .sections li,
.care__hero__content .sections a {
  color: #fdfdfd;
}
.care__hero__content__data {
  position: relative;
  padding-right: 8rem;
  width: 37.25rem;
}
.care__hero__content__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1;
  color: #fdfdfd;
  text-transform: uppercase;
}
.care__hero__content__data__text {
  margin-top: 2.5rem;
  padding-right: 8rem;
  font-size: 1.375rem;
  line-height: 1.4;
  color: #fdfdfd;
}
.care__hero__content__data__button {
  margin-top: 3.5rem;
}
.care__hero__content__data__button button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #ffeb00 !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1c1733;
  text-transform: uppercase;
  border: 0 !important;
  line-height: 1.4;
  padding: 0 2.25rem;
}
@media (max-width: 1200px) {
  .care__hero__content__data__button button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
.care__third__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 9rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .care__third__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .care__third__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
  }
}
.care__third__content__left {
  width: 37.1875rem;
  margin-right: 17.875rem;
}
@media (max-width: 1200px) {
  .care__third__content__left {
    width: 100%;
    margin-right: 0;
  }
}
.care__third__content__left__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.care__third__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .care__third__content__left__title {
    font-size: 2rem;
  }
}
.care__third__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
  width: 37.1875rem;
}
@media (max-width: 1200px) {
  .care__third__content__left__text {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.care__third__content__left__lines {
  margin-top: 4.5rem;
}
@media (max-width: 1200px) {
  .care__third__content__left__lines {
    margin-top: 1.5rem;
  }
}
.care__third__content__left__lines__line {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
}
.care__third__content__left__lines__line svg {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: #1f1f1f;
}
.care__third__content__left__lines__line__text {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .care__third__content__left__lines__line__text {
    font-size: 1rem;
  }
}
.care__third__content__left__info {
  margin-top: 4.5rem;
  padding: 2.5rem;
  background-color: #f5f5f5;
  border-radius: 1rem;
}
@media (max-width: 1200px) {
  .care__third__content__left__info {
    margin-top: 1.5rem;
    padding: 1.5rem;
  }
}
.care__third__content__left__info__name {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .care__third__content__left__info__name {
    font-size: 1.5rem;
  }
}
.care__third__content__left__info__text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .care__third__content__left__info__text {
    font-size: 1rem;
    margin-top: 1rem;
  }
}
.care__third__content__photo {
  width: 29.375rem;
  height: 45.625rem;
  border-radius: 1rem;
  background-color: #c8c8c8;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .care__third__content__photo {
    width: 100%;
    height: auto;
    margin-top: 1.5rem;
  }
}
.care__third__content__photo img {
  height: 100%;
}
@media (max-width: 1200px) {
  .care__third__content__photo img {
    display: block;
    width: 100%;
    height: auto;
  }
}
