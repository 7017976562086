.goalies__hero {
  position: relative;
  height: 46.25rem;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .goalies__hero {
    height: auto;
  }
}
.goalies__hero > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media (max-width: 1200px) {
  .goalies__hero > img {
    position: relative;
    height: auto;
  }
}
.goalies__hero__content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .goalies__hero__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .goalies__hero__content {
    display: none;
  }
}
.goalies__hero__content .sections {
  position: absolute;
  left: 4rem;
  top: 1.5rem;
}
.goalies__hero__content .sections li,
.goalies__hero__content .sections a {
  color: #fdfdfd;
}
.goalies__hero__content__data {
  position: relative;
  padding-right: 8rem;
  width: 40rem;
}
.goalies__hero__content__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1;
  color: #fdfdfd;
  text-transform: uppercase;
}
.goalies__hero__content__data__text {
  margin-top: 2.5rem;
  padding-right: 8rem;
  font-size: 1.375rem;
  line-height: 1.4;
  color: #fdfdfd;
}
.goalies__hero__content__data__button {
  margin-top: 3.5rem;
}
.goalies__hero__content__data__button button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #ffeb00 !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1c1733;
  text-transform: uppercase;
  border: 0 !important;
  line-height: 1.4;
  padding: 0 2.25rem;
}
@media (max-width: 1200px) {
  .goalies__hero__content__data__button button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
.goalies__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .goalies__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .goalies__content {
    flex-direction: column;
  }
}
.goalies__content__item {
  position: relative;
  width: calc((100% - 3 * 2rem) / 4);
  margin-top: 7.5rem;
  margin-right: 2rem;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .goalies__content__item {
    width: 100%;
    margin-top: 2rem;
    margin-right: 0;
  }
}
.goalies__content__item__photo {
  height: 16.1875rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .goalies__content__item__photo {
    height: 10rem;
  }
}
.goalies__content__item__photo img {
  max-width: 12rem;
  max-height: 7rem;
  mix-blend-mode: multiply;
}
@media (max-width: 1200px) {
  .goalies__content__item__photo img {
    max-width: 10rem;
  }
}
.goalies__content__item__title {
  margin-top: 1.5rem;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.4;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .goalies__content__item__title {
    font-size: 1rem;
  }
}
.goalies__content__item__team {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #8b8b8b;
}
.goalies__content__item__text {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .goalies__content__item__text {
    font-size: 0.875rem;
  }
}
.goalies__content .goalies__content__item:nth-child(4n + 4) {
  margin-right: 0;
}
.goalies__down {
  background-color: #f7f7f7;
  margin-top: 7.5rem;
}
@media (max-width: 1200px) {
  .goalies__down {
    margin-top: 2rem;
  }
}
.goalies__down__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 1200px) {
  .goalies__down__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .goalies__down__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    flex-direction: column;
  }
}
.goalies__down__content__left {
  width: 22rem;
  margin-right: 9.375rem;
}
@media (max-width: 1200px) {
  .goalies__down__content__left {
    width: 100%;
    margin-right: 0;
  }
}
.goalies__down__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .goalies__down__content__left__title {
    font-size: 2rem;
  }
}
.goalies__down__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .goalies__down__content__left__text {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.goalies__down__content__left button {
  margin-top: 3.5rem;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.4;
  color: #2b9db4;
}
@media (max-width: 1200px) {
  .goalies__down__content__left button {
    margin-top: 1.5rem;
  }
}
.goalies__down__content__left button svg {
  margin-right: 0.5rem;
}
.goalies__down__content__left button:hover {
  text-decoration: underline;
}
.goalies__down__content__photo {
  width: 45rem;
  height: 30rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: white;
}
@media (max-width: 1200px) {
  .goalies__down__content__photo {
    width: 100%;
    height: auto;
    margin-top: 2rem;
  }
}
.goalies__down__content__photo img {
  width: 100%;
}
@media (max-width: 1200px) {
  .goalies__down__content__photo img {
    display: block;
  }
}
