.custom__hero {
  position: relative;
  height: 46.25rem;
  background-color: #1f1f1f;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .custom__hero {
    height: auto;
  }
}
.custom__hero > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0.5;
}
@media (max-width: 1200px) {
  .custom__hero > img {
    position: relative;
    height: auto;
  }
}
.custom__hero__content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
}
@media (max-width: 1200px) {
  .custom__hero__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .custom__hero__content {
    display: none;
  }
}
.custom__hero__content .sections {
  position: absolute;
  left: 4rem;
  top: 1.5rem;
}
.custom__hero__content .sections li,
.custom__hero__content .sections a {
  color: #fdfdfd;
}
.custom__hero__content__data {
  position: relative;
  padding-right: 8rem;
  width: 37.25rem;
}
.custom__hero__content__data__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1;
  color: #fdfdfd;
  text-transform: uppercase;
}
.custom__hero__content__data__text {
  margin-top: 2.5rem;
  padding-right: 8rem;
  font-size: 1.375rem;
  line-height: 1.4;
  color: #fdfdfd;
}
.custom__hero__content__data__button {
  margin-top: 3.5rem;
}
.custom__hero__content__data__button button {
  height: 3.5rem;
  border-radius: 0.5rem;
  background-color: #ffeb00 !important;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.7px;
  color: #1c1733;
  text-transform: uppercase;
  border: 0 !important;
  line-height: 1.4;
  padding: 0 2.25rem;
}
@media (max-width: 1200px) {
  .custom__hero__content__data__button button {
    padding: 0 1.5rem;
    white-space: nowrap;
  }
}
.custom__process__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 6rem;
  padding-bottom: 13.5rem;
}
@media (max-width: 1200px) {
  .custom__process__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .custom__process__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.custom__process__content__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.custom__process__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .custom__process__content__title {
    font-size: 2rem;
  }
}
.custom__process__content__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .custom__process__content__text {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
.custom__process__content__panels {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .custom__process__content__panels {
    margin-top: 1.5rem;
    flex-direction: column;
  }
}
.custom__process__content__panels__panel {
  flex: 1;
  padding: 0 1.5rem;
  margin-right: 2rem;
  border-left: 1px solid #c8c8c8;
}
@media (max-width: 1200px) {
  .custom__process__content__panels__panel {
    margin-right: 0;
    margin-top: 2rem;
  }
}
.custom__process__content__panels__panel__number {
  font-size: 2rem;
  font-weight: bold;
  line-height: 0.8;
  color: #c8c8c8;
}
@media (max-width: 1200px) {
  .custom__process__content__panels__panel__number {
    font-size: 1.5rem;
  }
}
.custom__process__content__panels__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 6rem;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .custom__process__content__panels__panel__title {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
}
.custom__process__content__panels__panel__text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
@media (max-width: 1200px) {
  .custom__process__content__panels__panel__text {
    font-size: 1rem;
  }
}
.custom__process__content__panels__panel:last-of-type {
  margin-right: 0;
}
.custom__pricing__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-bottom: 13.5rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .custom__pricing__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.custom__pricing__content__left {
  width: 37.1875rem;
}
.custom__pricing__content__left__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.custom__pricing__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
.custom__pricing__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
.custom__pricing__content__right {
  margin-left: 9.8125rem;
  width: 29.25rem;
  font-size: 1.125rem;
  padding-top: 7.75rem;
}
.custom__pricing__content__right__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #c8c8c8;
}
.custom__pricing__content__right__row:first-of-type {
  font-weight: bold;
  border-bottom: 2px solid #c8c8c8;
}
.custom__pricing__content__right__row:last-of-type {
  border-bottom: 0;
}
.custom__choose__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-bottom: 9rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .custom__choose__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.custom__choose__content__left {
  width: 37.1875rem;
}
.custom__choose__content__left__section {
  font-size: 0.875rem;
  line-height: 0.86;
  letter-spacing: 7px;
  color: #8b8b8b;
  text-transform: uppercase;
}
.custom__choose__content__left__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 1.5rem;
  font-size: 3.5rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  line-height: 1.1;
  color: #1f1f1f;
  text-transform: uppercase;
}
.custom__choose__content__left__text {
  margin-top: 2.5rem;
  font-size: 1.125rem;
  line-height: 1.6;
  color: #1f1f1f;
}
.custom__choose__content__left button {
  margin-top: 4.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.7px;
  color: #2b9db4;
  text-transform: uppercase;
}
.custom__choose__content__left button svg {
  margin-left: 0.5rem;
}
.custom__choose__content__left button:hover {
  text-decoration: underline;
}
.custom__choose__content__right {
  margin-left: 9.8125rem;
  width: 29.25rem;
  font-size: 1.125rem;
  padding-top: 7.75rem;
}
.custom__choose__content__right__box {
  border-radius: 1rem;
  background-color: #f5f5f5;
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.custom__choose__content__right__box__value svg {
  width: 4rem;
  height: 4rem;
  color: #c8c8c8;
}
.custom__choose__content__right__box__value__text {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.2;
  color: #1f1f1f;
  text-transform: uppercase;
}
