@import 'variables';

.warning {
    position: sticky;
    top: 0;
    background-color: @colorPrimary;
    height: 40px;
    z-index: 200;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: @colorBlue;

    @media (max-width: @mobileBreakpoint) {
        font-size: 12px;
    }
}
