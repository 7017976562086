.warning {
  position: sticky;
  top: 0;
  background-color: #ffeb00;
  height: 40px;
  z-index: 200;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #1c1733;
}
@media (max-width: 1200px) {
  .warning {
    font-size: 12px;
  }
}
