@import 'variables';

.slideshow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000000;
    display: flex;
    justify-content: center;
    align-items: center;

    button.slideshow__background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    button.slideshow__close {
        position: absolute;
        right: 50px;
        top: 50px;
        width: 50px;
        height: 50px;

        @media (max-width: @mobileBreakpoint) {
            right: 10px;
            top: 10px;
            width: 30px;
            height: 30px;
        }

        svg {
            width: 100%;
            height: 100%;
            color: white;
        }
    }

    &__photo {
        position: relative;
        width: 100%;
        max-width: 1200px;

        img {
            display: block;
            width: 100%;
        }

        button.slideshow__prev {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: rgba(0,0,0,0.5);

            svg {
                position: absolute;
                left: 15px;
                top: 10px;
                width: 30px;
                height: 30px;
                color: white;
            }
        }

        button.slideshow__next {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            right: 10px;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: rgba(0,0,0,0.5);

            svg {
                position: absolute;
                right: 8px;
                top: 10px;
                width: 30px;
                height: 30px;
                color: white;
            }
        }
    }
}
