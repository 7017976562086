.blog__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-bottom: 1.5rem;
}
@media (max-width: 1200px) {
  .blog__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.blog__content__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  margin-top: 3.5rem;
  font-size: 6rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: italic;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .blog__content__title {
    font-size: 4rem;
    margin-top: 1.5rem;
  }
}
.blog__content__text {
  margin-top: 2.5rem;
  width: 37.1875rem;
  font-size: 1.125rem;
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .blog__content__text {
    margin-top: 1.5rem;
    width: 100%;
    font-size: 1rem;
  }
}
.blog__content__data {
  position: relative;
  margin-top: 6rem;
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .blog__content__data {
    margin-top: 0;
    flex-direction: column;
  }
}
.blog__content__data__categories {
  margin-right: 2rem;
  width: calc((100% - 3 * 2rem) / 4);
}
@media (max-width: 1200px) {
  .blog__content__data__categories {
    margin-right: 0;
    width: 100%;
    margin-top: 1.5rem;
  }
}
.blog__content__data__categories__content {
  border-radius: 16px;
  background-color: #f5f5f5;
  padding: 2.5rem;
  box-sizing: border-box;
}
@media (max-width: 1200px) {
  .blog__content__data__categories__content {
    padding: 1.5rem;
  }
}
.blog__content__data__categories__content__title {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.6;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}
@media (max-width: 1200px) {
  .blog__content__data__categories__content__title {
    font-size: 0.875rem;
  }
}
.blog__content__data__categories__content button.blog__content__data__categories__content__category {
  display: block;
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .blog__content__data__categories__content button.blog__content__data__categories__content__category {
    font-size: 0.875rem;
  }
}
.blog__content__data__categories__content .active {
  font-weight: bold;
}
.blog__content__data__articles {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1200px) {
  .blog__content__data__articles {
    flex-direction: column;
    margin-top: 2rem;
  }
}
.blog__content__data__articles__article,
.blog__content__data__articles button.blog__content__data__articles__article {
  margin-right: 2rem;
  margin-bottom: 6rem;
  width: calc((100% - 2 * 2rem) / 3);
  cursor: pointer;
  display: block;
  text-align: left;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article,
  .blog__content__data__articles button.blog__content__data__articles__article {
    margin-right: 0;
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
.blog__content__data__articles__article__photo,
.blog__content__data__articles button.blog__content__data__articles__article__photo {
  height: 16.125rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article__photo,
  .blog__content__data__articles button.blog__content__data__articles__article__photo {
    height: auto;
  }
}
.blog__content__data__articles__article__photo img,
.blog__content__data__articles button.blog__content__data__articles__article__photo img {
  display: block;
  height: 100%;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article__photo img,
  .blog__content__data__articles button.blog__content__data__articles__article__photo img {
    width: 100%;
    height: auto;
  }
}
.blog__content__data__articles__article__categories,
.blog__content__data__articles button.blog__content__data__articles__article__categories {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  line-height: 1.3;
  color: #8b8b8b;
}
.blog__content__data__articles__article__title,
.blog__content__data__articles button.blog__content__data__articles__article__title {
  margin-top: 0.5rem;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.4;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article__title,
  .blog__content__data__articles button.blog__content__data__articles__article__title {
    font-size: 1.125rem;
  }
}
.blog__content__data__articles__article__text,
.blog__content__data__articles button.blog__content__data__articles__article__text {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.6;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article__text,
  .blog__content__data__articles button.blog__content__data__articles__article__text {
    font-size: 0.875rem;
  }
}
.blog__content__data__articles__article button,
.blog__content__data__articles button.blog__content__data__articles__article button {
  margin-top: 3.75rem;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.7px;
  color: #2b9db4;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .blog__content__data__articles__article button,
  .blog__content__data__articles button.blog__content__data__articles__article button {
    margin-top: 2rem;
  }
}
.blog__content__data__articles__article button svg,
.blog__content__data__articles button.blog__content__data__articles__article button svg {
  margin-left: 0.5rem;
}
.blog__content__data__articles__article:nth-child(3n + 3),
.blog__content__data__articles .blog__content__data__articles__article:nth-child(3n + 3) {
  margin-right: 0;
}
