@import 'variables';

.blog {

    &__content {
        .content();

        padding-bottom: 1.5rem;

        &__title {
            .fontBarlow();

            margin-top: 3.5rem;
            font-size: 6rem;
            font-weight: bold;
            font-stretch: condensed;
            font-style: italic;
            text-transform: uppercase;

            @media (max-width: @mobileBreakpoint) {
                font-size: 4rem;
                margin-top: @spacingM;
            }
        }

        &__text {
            margin-top: 2.5rem;
            width: 37.1875rem;
            font-size: 1.125rem;
            line-height: 1.6;

            @media (max-width: @mobileBreakpoint) {
                margin-top: @spacingM;
                width: 100%;
                font-size: 1rem;
            }
        }

        &__data {
            position: relative;
            margin-top: 6rem;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                margin-top: 0;
                flex-direction: column;
            }

            &__categories {
                margin-right: @spacingL;
                width: ~"calc((100% - 3 * @{spacingL}) / 4)";

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                    width: 100%;
                    margin-top: @spacingM;
                }

                &__content {
                    border-radius: 16px;
                    background-color: #f5f5f5;
                    padding: 2.5rem;
                    box-sizing: border-box;

                    @media (max-width: @mobileBreakpoint) {
                        padding: @spacingM;
                    }

                    &__title {
                        font-size: 1.125rem;
                        font-weight: bold;
                        line-height: 1.6;
                        text-transform: uppercase;
                        padding-bottom: @spacingXXS;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }

                    button.blog__content__data__categories__content__category {
                        display: block;
                        margin-top: @spacingS;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        cursor: pointer;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }

                    .active {
                        font-weight: bold;
                    }
                }
            }

            &__articles {
                position: relative;
                flex: 1;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: @mobileBreakpoint) {
                    flex-direction: column;
                    margin-top: @spacingL;
                }

                &__article, button.blog__content__data__articles__article {
                    margin-right: @spacingL;
                    margin-bottom: 6rem;
                    width: ~"calc((100% - 2 * @{spacingL}) / 3)";
                    cursor: pointer;
                    display: block;
                    text-align: left;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        margin-bottom: @spacingM;
                        width: 100%;
                    }

                    &__photo {
                        height: 16.125rem;
                        border-radius: 1rem;
                        overflow: hidden;
                        background-color: rgb(250,250,250);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: @mobileBreakpoint) {
                            height: auto;
                        }

                        img {
                            display: block;
                            height: 100%;

                            @media (max-width: @mobileBreakpoint) {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    &__categories {
                        margin-top: @spacingM;
                        font-size: 0.75rem;
                        line-height: 1.3;
                        color: #8b8b8b;
                    }

                    &__title {
                        margin-top: @spacingXXS;
                        font-size: 1.375rem;
                        font-weight: bold;
                        line-height: 1.4;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.125rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingS;
                        font-size: 1.125rem;
                        line-height: 1.6;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 0.875rem;
                        }
                    }

                    button {
                        margin-top: 3.75rem;
                        font-size: 0.875rem;
                        font-weight: bold;
                        line-height: 1.4;
                        letter-spacing: 0.7px;
                        color: @colorSecondary;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingL;
                        }

                        svg {
                            margin-left: @spacingXXS;
                        }
                    }
                }
                &__article:nth-child(3n + 3), .blog__content__data__articles__article:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
        }
    }
}
