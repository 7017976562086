@import 'variables';

nav.sections {
    margin-top: @spacingM;

    li, a {
        color: #8b8b8b;
        font-size: 0.75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
    a:hover {
        text-decoration: underline;
    }
}
