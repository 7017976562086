@import 'variables';

.goalies {
    &__hero {
        position: relative;
        height: 46.25rem;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            height: auto;
        }

        >img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            @media (max-width: @mobileBreakpoint) {
                position: relative;
                height: auto;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            .content();

            @media (max-width: @mobileBreakpoint) {
                display: none;
            }

            .sections {
                position: absolute;
                left: @spacing2XL;
                top: @spacingM;

                li, a {
                    color: @colorBaseColor;
                }
            }

            &__data {
                position: relative;
                padding-right: 8rem;
                width: 40rem;

                &__title {
                    .fontBarlow();

                    font-size: 6rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1;
                    color: @colorBaseColor;
                    text-transform: uppercase;
                }

                &__text {
                    margin-top: 2.5rem;
                    padding-right: 8rem;
                    font-size: 1.375rem;
                    line-height: 1.4;
                    color: @colorBaseColor;
                }

                &__button {
                    margin-top: 3.5rem;

                    button {
                        .buttonPrimary();
                    }
                }
            }
        }
    }

    &__content {
        .content();

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (max-width: @mobileBreakpoint) {
            flex-direction: column;
        }

        &__item {
            position: relative;
            width: ~"calc((100% - 3 * @{spacingL}) / 4)";
            margin-top: 7.5rem;
            margin-right: @spacingL;
            cursor: pointer;

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
                margin-top: @spacingL;
                margin-right: 0;
            }

            &__photo {
                height: 16.1875rem;
                border-radius: 1rem;
                overflow: hidden;
                background-color: rgb(250,250,250);
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    height: 10rem;
                }

                img {
                    max-width: 12rem;
                    max-height: 7rem;
                    mix-blend-mode: multiply;

                    @media (max-width: @mobileBreakpoint) {
                        max-width: 10rem;
                    }
                }
            }

            &__title {
                margin-top: @spacingM;
                font-size: 1.375rem;
                font-weight: bold;
                line-height: 1.4;
                color: @colorDark;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 1rem;
                }
            }

            &__team {
                margin-top: @spacingXXS;
                font-size: 0.75rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.3;
                letter-spacing: normal;
                text-align: left;
                color: #8b8b8b;
            }

            &__text {
                margin-top: @spacingS;
                font-size: 1.125rem;
                line-height: 1.6;
                color: @colorDark;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.875rem;
                }
            }
        }
        .goalies__content__item:nth-child(4n + 4) {
            margin-right: 0;
        }
    }

    &__down {
        background-color: #f7f7f7;
        margin-top: 7.5rem;

        @media (max-width: @mobileBreakpoint) {
            margin-top: @spacingL;
        }

        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: @spacing5XL;
            display: flex;
            flex-direction: row;
            justify-content: center;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingM;
                padding-bottom: @spacingM;
                flex-direction: column;
            }

            &__left {
                width: 22rem;
                margin-right: 9.375rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-right: 0;
                }

                &__title {
                    .fontBarlow();

                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2rem;
                    }
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                        font-size: 1rem;
                    }
                }

                button {
                    margin-top: 3.5rem;
                    font-size: 1.375rem;
                    font-weight: bold;
                    line-height: 1.4;
                    color: @colorSecondary;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                    }

                    svg {
                        margin-right: @spacingXXS;
                    }
                }
                button:hover {
                    text-decoration: underline;
                }
            }

            &__photo {
                width: 45rem;
                height: 30rem;
                border-radius: 1rem;
                overflow: hidden;
                background-color: white;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    height: auto;
                    margin-top: @spacingL;
                }

                img {
                    width: 100%;

                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                    }
                }
            }
        }
    }
}
