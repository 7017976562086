.footer {
  background-color: #1f1f1f;
}
.footer__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 4.5rem;
  padding-bottom: 3rem;
  color: #fdfdfd;
}
@media (max-width: 1200px) {
  .footer__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .footer__content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.footer__content__panels {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1200px) {
  .footer__content__panels {
    flex-direction: column;
  }
}
.footer__content__panels__panel {
  position: relative;
  margin-right: 2rem;
  flex: 1;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
.footer__content__panels__panel__social {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel__social {
    display: none;
  }
}
.footer__content__panels__panel__social img {
  display: block;
  margin-bottom: 1.5rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.footer__content__panels__panel__logo > svg {
  width: auto;
  height: 24px;
}
.footer__content__panels__panel__slogan {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  width: 8.125rem;
  opacity: 0.75;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel__slogan {
    display: none;
  }
}
.footer__content__panels__panel__title {
  font-family: 'Barlow Condensed', sans-serif !important;
  font-size: 1rem;
  font-weight: normal;
  font-size: 1.25rem;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: left;
  padding-bottom: 1rem;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel__title {
    font-size: 1rem;
  }
}
.footer__content__panels__panel button {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  margin-top: 1rem;
  display: block;
  color: #fdfdfd;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel button {
    margin-top: 0.75rem;
    font-size: 0.875rem;
  }
}
.footer__content__panels__panel__contact {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
}
.footer__content__panels__panel__contact__left {
  margin-right: 0.5rem;
}
.footer__content__panels__panel__contact__left svg {
  width: 24px;
  height: 24px;
  color: #fdfdfd;
}
.footer__content__panels__panel__contact__right__value {
  font-size: 1.375rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
}
@media (max-width: 1200px) {
  .footer__content__panels__panel__contact__right__value {
    font-size: 1.25rem;
  }
}
.footer__content__panels__panel__contact__right__text {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #fdfdfd;
  opacity: 0.75;
}
.footer__content__panels .footer__content__panels__panel:last-of-type {
  margin-right: 0;
}
.footer__content__down {
  margin-top: 4.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e6e6e6;
}
@media (max-width: 1200px) {
  .footer__content__down {
    margin-top: 0;
  }
}
.footer__content__down__text {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #fdfdfd;
  opacity: 0.75;
}
