@import 'variables';

.custom {
    &__hero {
        position: relative;
        height: 46.25rem;
        background-color: @colorDark;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            height: auto;
        }

        >img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0.5;

            @media (max-width: @mobileBreakpoint) {
                position: relative;
                height: auto;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            .content();

            @media (max-width: @mobileBreakpoint) {
                display: none;
            }

            .sections {
                position: absolute;
                left: @spacing2XL;
                top: @spacingM;

                li, a {
                    color: @colorBaseColor;
                }
            }

            &__data {
                position: relative;
                padding-right: 8rem;
                width: 37.25rem;

                &__title {
                    .fontBarlow();

                    font-size: 6rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1;
                    color: @colorBaseColor;
                    text-transform: uppercase;
                }

                &__text {
                    margin-top: 2.5rem;
                    padding-right: 8rem;
                    font-size: 1.375rem;
                    line-height: 1.4;
                    color: @colorBaseColor;
                }

                &__button {
                    margin-top: 3.5rem;

                    button {
                        .buttonPrimary();
                    }
                }
            }
        }
    }

    &__process {
        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: @spacing7XL;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                padding-bottom: @spacingL;
            }

            &__section {
                font-size: 0.875rem;
                line-height: 0.86;
                letter-spacing: 7px;
                color: #8b8b8b;
                text-transform: uppercase;
            }

            &__title {
                .fontBarlow();

                margin-top: @spacingM;
                font-size: 3.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 2rem;
                }
            }

            &__text {
                margin-top: 2.5rem;
                font-size: 1.125rem;
                line-height: 1.6;
                color: @colorDark;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    font-size: 1rem;
                }
            }

            &__panels {
                margin-top: 7.5rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    flex-direction: column;
                }

                &__panel {
                    flex: 1;
                    padding: 0 @spacingM;
                    margin-right: @spacingL;
                    border-left: 1px solid #c8c8c8;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        margin-top: @spacingL;
                    }

                    &__number {
                        font-size: 2rem;
                        font-weight: bold;
                        line-height: 0.8;
                        color: #c8c8c8;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.5rem;
                        }
                    }

                    &__title {
                        .fontBarlow();

                        margin-top: 6rem;
                        font-size: 2rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        line-height: 1.1;
                        color: @colorDark;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingM;
                            font-size: 1.5rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        color: @colorDark;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1rem;
                        }
                    }
                }
                &__panel:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &__pricing {
        &__content {
            .content();

            padding-bottom: @spacing7XL;
            display: flex;
            flex-direction: row;

            &__left {
                width: 37.1875rem;

                &__section {
                    font-size: 0.875rem;
                    line-height: 0.86;
                    letter-spacing: 7px;
                    color: #8b8b8b;
                    text-transform: uppercase;
                }

                &__title {
                    .fontBarlow();

                    margin-top: @spacingM;
                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;
                }
            }

            &__right {
                margin-left: 9.8125rem;
                width: 29.25rem;
                font-size: 1.125rem;
                padding-top: 7.75rem;

                &__row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: @spacingS 0;
                    border-bottom: 1px solid #c8c8c8;
                }
                &__row:first-of-type {
                    font-weight: bold;
                    border-bottom: 2px solid #c8c8c8;
                }
                &__row:last-of-type {
                    border-bottom: 0;
                }
            }
        }
    }

    &__choose {
        &__content {
            .content();

            padding-bottom: @spacing5XL;
            display: flex;
            flex-direction: row;

            &__left {
                width: 37.1875rem;

                &__section {
                    font-size: 0.875rem;
                    line-height: 0.86;
                    letter-spacing: 7px;
                    color: #8b8b8b;
                    text-transform: uppercase;
                }

                &__title {
                    .fontBarlow();

                    margin-top: @spacingM;
                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;
                }

                button {
                    margin-top: 4.5rem;
                    font-size: 0.875rem;
                    font-weight: bold;
                    line-height: 1.4;
                    letter-spacing: 0.7px;
                    color: @colorSecondary;
                    text-transform: uppercase;

                    svg {
                        margin-left: @spacingXXS;
                    }
                }
                button:hover {
                    text-decoration: underline;
                }
            }

            &__right {
                margin-left: 9.8125rem;
                width: 29.25rem;
                font-size: 1.125rem;
                padding-top: 7.75rem;

                &__box {
                    border-radius: 1rem;
                    background-color: #f5f5f5;
                    padding: 2.5rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &__value {
                        svg {
                            width: 4rem;
                            height: 4rem;
                            color: rgb(200,200,200);
                        }

                        &__text {
                            margin-top: 1.5rem;
                            font-size: 0.875rem;
                            font-weight: bold;
                            line-height: 1.2;
                            color: @colorDark;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
