.menu {
  position: sticky;
  top: 40px;
  background-color: #fdfdfd;
  z-index: 200;
  border-bottom: 1px solid #f5f5f5;
}
.menu__content {
  margin: auto;
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 0 4rem;
  padding-top: 0.75rem;
  padding-bottom: 2rem;
}
@media (max-width: 1200px) {
  .menu__content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 1200px) {
  .menu__content {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
.menu__content__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
}
@media (max-width: 1200px) {
  .menu__content__header__left {
    opacity: 0;
  }
}
.menu__content__header__right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__content__header__right__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu__content__header__right__buttons button {
  display: inline-flex;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid #1f1f1f;
  color: #1f1f1f;
  font-size: 0.75rem;
  white-space: nowrap;
}
.menu__content__header__right__buttons button:last-of-type {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.menu__content__header__right__buttons button.active {
  font-weight: bold;
}
@media (max-width: 1200px) {
  .menu__content__header__right__buttons button:nth-child(2) {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
  }
}
@media (max-width: 1200px) {
  .menu__content__header__right__buttons button:nth-child(3),
  .menu__content__header__right__buttons button:nth-child(4) {
    display: none;
  }
}
.menu__content__data {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .menu__content__data {
    margin-top: 1rem;
  }
}
.menu__content__data button.menu__content__data__logo {
  cursor: pointer;
  color: #1c1733;
  border-left: 8px solid #ffeb00;
  padding-left: 1rem;
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 1200px) {
  .menu__content__data button.menu__content__data__logo {
    font-size: 0.875rem;
    padding-left: 0.5rem;
    position: absolute;
    left: 1.5rem;
    top: 0.75rem;
  }
}
.menu__content__data__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1200px) {
  .menu__content__data__buttons {
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
  }
}
.menu__content__data__buttons button {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.83;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  color: #1f1f1f;
  margin-right: 1.5rem;
  border-bottom: 2px solid transparent;
}
@media (max-width: 1200px) {
  .menu__content__data__buttons button {
    display: none;
    margin-right: 1rem;
  }
}
.menu__content__data__buttons button:last-of-type {
  margin-right: 0;
}
.menu__content__data__buttons button.active {
  border-bottom: 2px solid #ffeb00;
}
@media (max-width: 1200px) {
  .menu__content__data__buttons button.active {
    color: #2b9db4 !important;
  }
}
.menu__content__data__buttons button:nth-child(1),
.menu__content__data__buttons button:nth-child(2),
.menu__content__data__buttons button:nth-child(3) {
  color: #2b9db4;
}
@media (max-width: 1200px) {
  .menu__content__data__buttons button:nth-child(1),
  .menu__content__data__buttons button:nth-child(2),
  .menu__content__data__buttons button:nth-child(3) {
    color: #1f1f1f;
  }
}
@media (max-width: 1200px) {
  .menu__content__data__buttons button:nth-child(1),
  .menu__content__data__buttons button:nth-child(2),
  .menu__content__data__buttons button:nth-child(3),
  .menu__content__data__buttons button:nth-child(4),
  .menu__content__data__buttons button:nth-child(5) {
    display: inline-flex;
  }
}
@media (max-width: 1200px) {
  .menu__content__data__buttons button:nth-child(5) {
    margin-right: 0;
  }
}
@media (max-width: 1200px) {
  .menu__content__data__right {
    display: none;
  }
}
