@import 'variables';

.aria {
    &__hero {
        position: relative;
        height: 46.25rem;
        overflow: hidden;

        @media (max-width: @mobileBreakpoint) {
            height: auto;
        }

        >img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            @media (max-width: @mobileBreakpoint) {
                position: relative;
                height: auto;
            }
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            .content();

            @media (max-width: @mobileBreakpoint) {
                width: 100%;
                box-sizing: border-box;
                padding: @spacingS;
            }

            .sections {
                position: absolute;
                left: @spacing2XL;
                top: @spacingM;

                @media (max-width: @mobileBreakpoint) {
                    display: none;
                }

                li, a {
                    color: @colorBaseColor;
                }
            }

            &__data {
                position: relative;
                width: 42rem;
                background-color: rgba(43, 157, 180,0.85);
                border-radius: 1rem;
                padding: @spacingL;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    padding: @spacingM;
                    box-sizing: border-box;
                }

                &__title {
                    .fontBarlow();

                    font-size: 6rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1;
                    color: @colorBaseColor;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 3rem;
                    }
                }

                &__text {
                    margin-top: 2.5rem;
                    padding-right: 8rem;
                    font-size: 1.375rem;
                    line-height: 1.4;
                    color: @colorBaseColor;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: 1rem;
                        padding-right: 0;
                        font-size: 1rem;
                    }
                }

                &__button {
                    margin-top: 3.5rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: 1rem;
                    }

                    button {
                        .buttonPrimary();

                        font-size: 1.25rem;
                        text-transform: none !important;

                        @media (max-width: @mobileBreakpoint) {
                            height: auto;
                            white-space: normal !important;
                            font-size: 0.875rem;
                            padding: @spacingS @spacingL;
                            text-align: left;
                        }
                    }
                }

                &__text-mini {
                    .fontBarlow();

                    font-stretch: condensed;
                    font-style: italic;
                    margin-top: @spacingS;
                    font-size: 1rem;
                    color: @colorBaseColor;
                }
            }
        }
    }

    &__values {
        background-color: #f5f5f5;

        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: 8.5rem;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                padding-bottom: @spacingL;
            }

            &__title {
                .fontBarlow();

                font-size: 3.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                text-align: center;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 2rem;
                }
            }

            &__panels {
                margin-top: 6rem;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: 0;
                    flex-direction: column;
                }

                &__panel {
                    flex: 1;
                    padding: 0 @spacingM;
                    margin-right: @spacingL;
                    border-left: 1px solid #c8c8c8;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        margin-top: @spacingL;
                    }

                    >svg {
                        width: 56px;
                        height: 56px;
                        color: #c8c8c8;
                    }

                    &__title {
                        .fontBarlow();

                        margin-top: 4.5rem;
                        font-size: 2rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        line-height: 1.1;
                        color: @colorDark;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingM;
                            font-size: 1.5rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        color: @colorDark;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1rem;
                        }
                    }

                    button {
                        margin-top: 3.5rem;
                        font-size: 0.875rem;
                        font-weight: bold;
                        line-height: 1.4;
                        letter-spacing: 0.7px;
                        color: @colorSecondary;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingM;
                            white-space: nowrap;
                        }

                        svg {
                            margin-left: @spacingXXS;
                        }
                    }
                    button:hover {
                        text-decoration: underline;
                    }
                }
                &__panel:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &__first {
        &__content {
            .content();

            padding-top: 6rem;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
            }

            &__section {
                font-size: 0.875rem;
                line-height: 0.86;
                letter-spacing: 7px;
                color: #8b8b8b;
                text-transform: uppercase;
            }

            &__title {
                .fontBarlow();

                margin-top: @spacingM;
                font-size: 3.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 2rem;
                }
            }

            &__photos {
                margin-top: 2.5rem;
                font-size: 1.25rem;
                color: @colorDark;
                width: 37.1875rem;
                line-height: 1.6;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    font-size: 1rem;
                    width: 100%;
                }
            }

            &__text {
                margin-top: 2.5rem;
                font-size: 1.125rem;
                line-height: 1.6;
                color: @colorDark;
                width: 37.1875rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-top: @spacingM;
                    font-size: 1rem;
                }
            }

            &__button {
                margin-top: 3.5rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                }

                button {
                    .buttonPrimary();

                    font-size: 1.25rem;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1rem;
                    }
                }
            }

            &__text-mini {
                .fontBarlow();

                margin-top: @spacingS;
                font-size: 1rem;
                color: @colorDark;
                font-stretch: condensed;
                font-style: italic;
            }

            &__panels {
                margin-top: 6rem;
                display: flex;
                flex-direction: row;

                @media (max-width: @mobileBreakpoint) {
                    margin-top: @spacingM;
                    flex-direction: column-reverse;
                }

                &__left {
                    width: 45rem;

                    @media (max-width: @mobileBreakpoint) {
                        width: 100%;
                    }

                    &__photo {
                        height: 31.625rem;
                        background-color: #d9d9d9;
                        border-radius: 1rem;
                        overflow: hidden;

                        @media (max-width: @mobileBreakpoint) {
                            height: auto;
                        }

                        img {
                            height: 100%;
                            width: auto;

                            @media (max-width: @mobileBreakpoint) {
                                display: block;
                                height: auto;
                                width: 100%;
                            }
                        }
                    }

                    &__texts {
                        margin-top: 4.5rem;
                        display: flex;
                        flex-direction: row;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingM;
                            flex-direction: column;
                        }

                        &__text {
                            flex: 1;

                            &__title {
                                .fontBarlow();

                                font-size: 2rem;
                                font-weight: bold;
                                font-stretch: condensed;
                                font-style: italic;
                                line-height: 1.1;
                                color: @colorDark;
                                text-transform: uppercase;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 1.5rem;
                                }
                            }

                            &__value {
                                margin-top: @spacingM;
                                font-size: 1.125rem;
                                line-height: 1.6;
                                color: @colorDark;

                                @media (max-width: @mobileBreakpoint) {
                                    font-size: 1rem;
                                }
                            }
                        }
                        &__text:first-of-type {
                            margin-right: @spacingXL;

                            @media (max-width: @mobileBreakpoint) {
                                margin-right: 0;
                                margin-bottom: @spacingM;
                            }
                        }
                    }
                }

                &__right {
                    margin-left: 12.8125rem;
                    width: 26.4375rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-left: 0;
                        width: 100%;
                        margin-top: @spacingM;
                    }

                    &__row {
                        margin-bottom: @spacingM;
                        display: flex;
                        flex-direction: row;

                        &__number {
                            font-size: 1.125rem;
                            font-weight: bold;
                            line-height: 1.6;
                            color: #c8c8c8;
                        }

                        &__text {
                            margin-left: @spacingM;
                            font-size: 1.125rem;
                            line-height: 1.6;
                            color: @colorDark;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }

        &__plan-buttons {
            .content();

            padding-top: @spacing3XL;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingXL;
                flex-wrap: wrap;
            }

            button {
                .button();

                margin-right: @spacingM;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.75rem;
                    padding: 0 0.75rem;
                    height: 2.5rem;
                    margin-right: @spacingS;
                    margin-bottom: @spacingS;
                }
            }
            button:last-of-type {
                margin-right: 0;
            }
            button.active {
                .buttonPrimary();

                @media (max-width: @mobileBreakpoint) {
                    font-size: 0.75rem;
                    padding: 0 0.75rem;
                    height: 2.5rem;
                }
            }
        }

        &__plan {
            .content();

            padding-top: @spacingM;
            padding-bottom: @spacing2XL;

            img {
                display: block;
                width: 100%;
            }
        }

        &__photos {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: @mobileBreakpoint) {
                margin-bottom: @spacingXL;
            }

            &__title {

                &__content {
                    .content();
                    .fontBarlow();

                    font-size: 2.5rem;
                    font-stretch: condensed;
                    font-style: italic;
                    font-weight: bold;
                    line-height: 1.1;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 1.5rem;
                    }
                }

                width: 100%;
                margin-bottom: @spacingXL;
            }

            button {
                width: ~"calc(100% / 5)";

                @media (max-width: @mobileBreakpoint) {
                    width: ~"calc(100% / 2)";
                }

                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    &__second {
        background-color: #f5f5f5;

        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: @spacing5XL;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                padding-bottom: @spacingL;
            }

            &__section {
                font-size: 0.875rem;
                line-height: 0.86;
                letter-spacing: 7px;
                color: #8b8b8b;
                text-transform: uppercase;
            }

            &__title {
                .fontBarlow();

                margin-top: @spacingM;
                font-size: 3.5rem;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: 1.1;
                color: @colorDark;
                text-transform: uppercase;

                @media (max-width: @mobileBreakpoint) {
                    font-size: 2rem;
                }
            }

            &__text {
                margin-top: 2.5rem;
                font-size: 1.125rem;
                line-height: 1.6;
                color: @colorDark;
                width: 37.1875rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    margin-top: @spacingM;
                    font-size: 1rem;
                }
            }

            &__panels {
                &__panel {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-top: 6rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingL;
                        flex-direction: column;
                    }

                    &__left {
                        margin-left: 7.8125rem;
                        width: 37.1875rem;

                        @media (max-width: @mobileBreakpoint) {
                            margin-left: 0;
                            width: 100%;
                        }

                        &__title {
                            .fontBarlow();

                            font-size: 2rem;
                            font-weight: bold;
                            font-stretch: condensed;
                            font-style: italic;
                            line-height: 1.1;
                            color: @colorDark;
                            text-transform: uppercase;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1.5rem;
                            }
                        }

                        &__text {
                            margin-top: @spacingM;
                            font-size: 1.125rem;
                            line-height: 1.6;
                            color: @colorDark;

                            @media (max-width: @mobileBreakpoint) {
                                font-size: 1rem;
                            }
                        }

                        button {
                            margin-top: 4.5rem;
                            font-size: 0.875rem;
                            font-weight: bold;
                            line-height: 1.4;
                            letter-spacing: 0.7px;
                            color: @colorSecondary;
                            text-transform: uppercase;

                            @media (max-width: @mobileBreakpoint) {
                                margin-top: @spacingM;
                            }

                            svg {
                                margin-left: @spacingXXS;
                            }
                        }
                        button:hover {
                            text-decoration: underline;
                        }
                    }

                    &__photo {
                        margin-left: 9.8125rem;
                        width: 29.4375rem;
                        height: 29.4375rem;
                        background-color: #d9d9d9;
                        border-radius: 1rem;
                        overflow: hidden;

                        @media (max-width: @mobileBreakpoint) {
                            margin-left: 0;
                            width: 100%;
                            height: auto;
                            margin-top: @spacingM;
                        }

                        img {
                            height: 100%;

                            @media (max-width: @mobileBreakpoint) {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    &__third {
        &__content {
            .content();

            padding-top: 6rem;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                flex-direction: column;
            }

            &__left {
                width: 29.3125rem;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                }

                &__section {
                    font-size: 0.875rem;
                    line-height: 0.86;
                    letter-spacing: 7px;
                    color: #8b8b8b;
                    text-transform: uppercase;
                }

                &__title {
                    .fontBarlow();

                    margin-top: @spacingM;
                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2rem;
                    }
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                        font-size: 1rem;
                    }
                }
            }

            &__right {
                position: relative;
                flex: 1;
                margin-left: 9.875rem;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: @mobileBreakpoint) {
                    margin-left: 0;
                    flex-direction: column;
                    padding-top: @spacingL;
                }

                &__item {
                    margin-right: @spacingM;
                    padding-left: @spacingM;
                    margin-bottom: @spacing5XL;
                    width: ~"calc((50% - @{spacingM} * 2))";
                    border-left: 1px solid #c8c8c8;

                    @media (max-width: @mobileBreakpoint) {
                        margin-right: 0;
                        margin-bottom: @spacingL;
                        width: 100%;
                    }

                    &__name {
                        .fontBarlow();

                        font-size: 2rem;
                        font-weight: bold;
                        font-stretch: condensed;
                        font-style: italic;
                        line-height: 1.1;
                        color: @colorDark;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1.5rem;
                        }
                    }

                    &__text {
                        margin-top: @spacingM;
                        font-size: 1.125rem;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.6;
                        letter-spacing: normal;
                        text-align: left;
                        color: @colorDark;

                        @media (max-width: @mobileBreakpoint) {
                            font-size: 1rem;
                            padding-right: @spacingXS;
                        }
                    }

                    button {
                        margin-top: 4.5rem;
                        font-size: 0.875rem;
                        font-weight: bold;
                        line-height: 1.4;
                        letter-spacing: 0.7px;
                        color: @colorSecondary;
                        text-transform: uppercase;

                        @media (max-width: @mobileBreakpoint) {
                            margin-top: @spacingM;
                        }

                        svg {
                            margin-left: @spacingXXS;
                        }
                    }
                    button:hover {
                        text-decoration: underline;
                    }
                }
                .aria__third__content__right__item:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }

    &__down {
        background-color: #f5f5f5;

        &__content {
            .content();

            padding-top: 6rem;
            padding-bottom: @spacing5XL;
            display: flex;
            flex-direction: row;

            @media (max-width: @mobileBreakpoint) {
                padding-top: @spacingL;
                padding-bottom: @spacingL;
                flex-direction: column-reverse;
            }

            &__left {
                margin-right: 9.875rem;
                width: 29.3125rem;

                @media (max-width: @mobileBreakpoint) {
                    margin-right: 0;
                    width: 100%;
                }

                &__title {
                    .fontBarlow();

                    font-size: 3.5rem;
                    font-weight: bold;
                    font-stretch: condensed;
                    font-style: italic;
                    line-height: 1.1;
                    color: @colorDark;
                    text-transform: uppercase;

                    @media (max-width: @mobileBreakpoint) {
                        font-size: 2rem;
                    }
                }

                &__text {
                    margin-top: 2.5rem;
                    font-size: 1.125rem;
                    line-height: 1.6;
                    color: @colorDark;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingM;
                        font-size: 1rem;
                    }
                }

                button {
                    .button();

                    margin-top: 4.5rem;

                    @media (max-width: @mobileBreakpoint) {
                        margin-top: @spacingL;
                    }
                }
            }

            &__photo {
                width: 45rem;
                height: 30rem;
                background-color: white;
                border-radius: 1rem;
                overflow: hidden;

                @media (max-width: @mobileBreakpoint) {
                    width: 100%;
                    height: auto;
                    margin-bottom: @spacingL;
                }

                img {
                    width: 100%;

                    @media (max-width: @mobileBreakpoint) {
                        display: block;
                    }
                }
            }
        }
    }
}
